import Cookies from "js-cookie";

export const removeCookies = () => {
  Cookies.remove("uid");
  Cookies.remove("client");
  Cookies.remove("access-token");
  Cookies.remove("paid_url");
  Cookies.remove("checkout");
  Cookies.remove("subscriber-token");
  Cookies.remove("uid", { domain: `.${process.env.NEXT_PUBLIC_DOMAIN}` });
  Cookies.remove("client", { domain: `.${process.env.NEXT_PUBLIC_DOMAIN}` });
  Cookies.remove("access-token", { domain: `.${process.env.NEXT_PUBLIC_DOMAIN}` });
  Cookies.remove("paid_url", { domain: `.${process.env.NEXT_PUBLIC_DOMAIN}` });
  Cookies.remove("checkout", { domain: `.${process.env.NEXT_PUBLIC_DOMAIN}` });
  Cookies.remove("subscriber-token", { domain: `.${process.env.NEXT_PUBLIC_DOMAIN}` });
  localStorage.clear();
};

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const userImage = (user) => {
  if (user?.__typename === "Subscriber") {
    return (
      <div className="rounded-full w-8 h-8 my-auto bg-gray-600 text-white text-xl hover:bg-gray-400 cursor-pointer flex justify-center items-center">
        {user.email?.charAt(0)?.toUpperCase()}
      </div>
    );
  } else {
    return (
      <img
        src={user?.imageUrl}
        className="rounded-full w-8 h-8 object-cover my-auto bg-white hover:bg-gray-200 cursor-pointer"
        alt="user"
      />
    );
  }
};

export const userResponsiveImage = (user, className) => {
  if(user.thumbnails?.length === 4) {
    return (
      <img
        id="srcSet"
        alt="user"
        className={className}
        srcSet={`${thumbnailSizeImage(user.thumbnails, 40)},
                  ${thumbnailSizeImage(user.thumbnails, 80)} 2x,
                  ${thumbnailSizeImage(user.thumbnails, 120)} 3x,
                  ${thumbnailSizeImage(user.thumbnails, 160)} 4x`}
        src={thumbnailSizeImage(user.thumbnails, 160)}
      />
    );
  }

  if (!user.thumbnail40) {
    return <img src={user.imageUrl} alt="user" className={className} />;
  }

  return (
    <img
      id="srcSet"
      alt="user"
      className={className}
      srcSet={`${user.thumbnail40.url},
                ${user.thumbnail80.url} 2x,
                ${user.thumbnail120.url} 3x,
                ${user.thumbnail160.url} 4x`}
      src={user.thumbnail160.url}
    />
  );
};

const thumbnailSizeImage = (thumbnails, size) => {
  for(let i = 0; i < thumbnails.length; i++) {
    if (thumbnails[i].size === size) {
      return thumbnails[i].url;
    }
  }
}

export const postResponsiveImages = (post, className) => {
  if(post.thumbnails?.length === 4) {
    return (
      <img
        id="srcSet"
        alt="post"
        className={className}
        srcSet={`${thumbnailSizeImage(post.thumbnails, 160)},
                ${thumbnailSizeImage(post.thumbnails, 320)} 2x,
                ${thumbnailSizeImage(post.thumbnails, 480)} 3x,
                ${thumbnailSizeImage(post.thumbnails, 640)} 4x`}
        src={thumbnailSizeImage(post.thumbnails, 640)}
      />
    );
  }

  if (!post.thumbnail160) {
    return (
      <img
        src={post.thumbnail}
        alt="post"
        className={className}
      />
    );
  }

  return (
    <img
      id="srcSet"
      alt="post"
      className={className}
      srcSet={`${post.thumbnail160.url},
              ${post.thumbnail320.url} 2x,
              ${post.thumbnail480.url} 3x,
              ${post.thumbnail640.url} 4x`}
      src={post.thumbnail160.url}
    />
  );

};
