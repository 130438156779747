import React, { Component, useEffect, useContext, useState } from 'react';
import Head from 'next/head'
import Cookies from "js-cookie";
import { Store } from '../store'
import { useManualQuery, useMutation } from 'graphql-hooks'
import { removeCookies } from './common/Utils'

const CURRENT_USER_QUERY = `
  query fetchCurrentUser {
    currentUser {
      id
      username
      role
      displayName
      xAccountName
      email
      imageUrl
      subscriber {
        id
      }
      notifications(first: 5) {
        nodes {
          id
          status
          createdAt
          notifiable {
            __typename
            ... on Subscription {
              id
              subscriber {
                email
                user {
                  imageUrl
                  displayName
                }
              }
              newsletter {
                title
                subdomain
              }
            }
            ... on Like {
              subscriber {
                email
                icon
                user {
                  displayName
                  imageUrl
                }
              }
              likable {
                __typename
                ... on Post {
                  id
                  title
                  url
                }
                ... on Comment {
                  id
                  url
                }
                ... on CommentReply {
                  id
                  url
                }
              }
            }
            ... on Payment {
              price
              user {
                email
                imageUrl
                displayName
              }
              payable {
                __typename
                ... on Subscription {
                  newsletter {
                    title
                    subdomain
                  }
                }
                ... on MonthlyBulk {
                  month
                  newsletter {
                    title
                    subdomain
                  }
                }
                ... on Post {
                  id
                  title
                  price
                  url
                }
              }
            }
            ... on Comment {
              url
              subscriber {
                email
                icon
                user {
                  displayName
                  imageUrl
                }
              }
              post {
                title
              }
            }
            ... on CommentReply {
              url
              subscriber {
                email
                icon
                user {
                  displayName
                  imageUrl
                }
              }
              comment {
                summary
              }
            }
            ... on PromotionEvent {
              id
              trialEndAt
              subscriber {
                user {
                  displayName
                  imageUrl
                }
              }
              promotion {
                newsletter {
                  id
                  subdomain
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`
const CURRENT_SUBSCRIBER_QUERY = `
  query fetchCurrentSubscriber {
    currentSubscriber {
      id
      email
      __typename
    }
  }
`

export function Layout({ children }) {
  const { state, dispatch } = useContext(Store)
  const [ getUser, { data: currentUserData, error: currentUserError }]
    = useManualQuery(
      CURRENT_USER_QUERY, {
        useCache: false
      }
    )
  const [ getSubscriber, { data: currentSubscriberData, error: currentSubscriberError }]
    = useManualQuery(
      CURRENT_SUBSCRIBER_QUERY, {
        useCache: false
      }
    )

  useEffect(() => {
    if(Cookies.get("access-token") && !state.currentUser) getUser()
    if(!Cookies.get("access-token") && Cookies.get("subscriber-token") && !state.currentUser) {
      getSubscriber()
    }
  }, [Cookies.get("access-token"), Cookies.get("subscriber-token")]);

  useEffect(() => {
    if(!currentUserData) return

    Cookies.remove("subscriber-token");
    Cookies.remove("subscriber-token", { domain: `.${process.env.NEXT_PUBLIC_DOMAIN}` });

    dispatch({
      type: 'CURRENT_USER',
      user: currentUserData.currentUser,
    })
  }, [currentUserData]);

  useEffect(() => {
    if(!currentSubscriberData) return

    dispatch({
      type: 'CURRENT_SUBSCRIBER',
      subscriber: currentSubscriberData.currentSubscriber,
    })
  }, [currentSubscriberData]);

  useEffect(() => {
    if (!currentUserError) return;

    removeCookies();
    dispatch({
      type: "CURRENT_USER",
      user: {},
    });
  }, [currentUserError]);

  useEffect(() => {
    if(!currentSubscriberError) return

    if (currentSubscriberError.graphQLErrors?.map(error => error?.message).includes('not_found')) {
      removeCookies();
      dispatch({
        type: "CURRENT_SUBSCRIBER",
        subscriber: {},
      });
      return
    }
  }, [currentSubscriberError]);

  return (
    <>
      <Head>
        <title>みんなのニュースレター</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/images/favicon/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/images/favicon/favicon-16x16.png" />
        <link rel="manifest" href="/images/favicon/site.webmanifest" />
        <link rel="mask-icon" href="/images/favicon/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
      </Head>
      {children}
    </>
  );
}

export default Layout;
